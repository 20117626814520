// eslint-disable-next-line no-var
export var CSRF = (window.CSRF = (function () {
   function recheckAll() {
      /**
       * Setup triggers on the .submit() function and the `submit` event so we
       * can add csrf inputs immediately before the form is submitted.
       */
      $$('form').each(function (form) {
         if (form.hasClass('ignoreCSRF') || form.hasClass('csrf-submit-handler-added')) {
            return;
         }
         // Ensure all forms submitted via a traditional 'submit' button have
         // an up-to-date CSRF input
         form
            .addEvent('submit', function () {
               ensureFormHasCSRFFormField(form);
            })
            .addClass('csrf-submit-handler-added');
         // Ensure all forms submitted via form.submit() have an up-to-date CSRF
         // input
         let oldSubmit = form.submit;
         // Wrap the default submit() function with our own
         form.submit = function () {
            ensureFormHasCSRFFormField(form);
            return oldSubmit.apply(form, Array.convert(arguments));
         };
      });
   }
   onDomReady(recheckAll);

   /**
    * Ensure the target of the given event (a Form element) has a CSRF token
    * input who's value is up to date.
    */
   function ensureFormHasCSRFFormField(form) {
      let csrfInput = form.getElement('.csrf');
      if (!csrfInput) {
         csrfInput = CSRF.formField();
         form.grab(csrfInput);
      } else {
         csrfInput.set('value', CSRF.get());
      }

      return csrfInput;
   }

   return {
      recheckAll: recheckAll,
      /**
       * ## CSRF.get()
       *
       * Read the value from the cookie that is set from the server
       */
      get: function () {
         return Cookie.read('csrf');
      },

      /**
       * Returns a new hidden input field with the CSRF token as a value.
       * Used when dynamically creating forms.
       */
      formField: function () {
         return new Element('input', {
            type: 'hidden',
            name: 'csrf',
            class: 'csrf',
            value: CSRF.get(),
         });
      },
   };
})());
